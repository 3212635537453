import React from "react"

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"


const Bio = ({ data }) => (
  <Layout>
    <Seo title="Welcome - SpecyalT" bodyClass='st' />
    
    <div className="column column--sidebar alignright">
      <div className="sticky">

        <span className={style.titleLowercase}>The artist known as <em>Specyal&nbsp;T</em> is an exceptionally talented, multi-instrumentalist, vocalist, songwriter, composer, producer, actor, writer &amp; poet; <em>who's on a mission to expand the urban market with her unique brand of sound.</em></span>
        <Link to='/st/album-credits'>Album Credits</Link>

      </div>
    </div>

    <div className="column column--content">
      
      <GatsbyImage className={style.imageBodyTwoThirds + ' float-right'} image={data.ProfileImage.childImageSharp.gatsbyImageData} alt="Specyal T Bio"/>

      <p><strong>A pianist from as early as two years of age, she began playing, with her foster parents encouragement, as much as possible on the family piano.</strong></p>
        
      <p>She was adopted shortly thereafter at the age of three and moved north of the city to a predominantly caucasian area. At six, Specyal T officially began her piano training through the Royal Conservatory Of Music up until the end of her high school career where she not only graduated with honours, she was also the first black valedictorian in her school’s 100+ year history. She was voted in unanimously by her peers and the teaching faculty.</p>
      
      <p>Specyal T partnered with Universal Music and Soul Kiss Entertainment and released her first single &amp; video “Ice Cream Cone” commercially featuring iconic Canadian rapper Michie Mee. The rotation &amp; charting of her second single "Sweat It Up!" and EP garnered attention from various music directors including CHUM FM, and her Dose Of Distinction studio album followed shortly thereafter debuting at #11 on the UK / R&amp;B album charts - an achievement that stands as a testament to Specyal T's incredible talent and work ethic. Having performed a staggering 2k+ shows in her career, Specyal T is not an artist to sit back and wait for success to come to her.</p>
      
      <p>With her naturally distinctive voice she is often compared to a young Lauryn Hill with a unique individual style as like hip hop superstar Missy Elliott! Her follow up studio album Dose Of Distinction 2 didn't disappoint with continued strong support from the UK and overseas in Europe. This album showed considerable growth from her debut effort. She’s an accomplished songwriter and along with her own material, she’s also written and produced for several other talented artists. Most notably, Catherine Marie’s debut single “Lie To Me” off the EP Go Tell Them was picked up by Virgin Radio, various satellite radio stations, as well as charted on North American DJ Pools. The lead single “Go Tell Them” another Specyal T Production, caught the attention of radio programmers at Kiss FM.</p>
      
      <p>2017 brought unexpected heartbreak and tragedy for Specyal T, with the loss of her youngest daughter. Through her pain she released her third studio EP Tionne, a ground-breaking concept album, which also is a tribute to her late daughter. This body of work has further expanded T’s undeniable musical mind, and is continuing to help shed light on a topic that the industry has yet to see. Her musical creativity pushes forward, even through the pandemic. Specyal T has consistently released new music which has amassed a steady amount of media attention, playlist features and radio play; further proving her staying power. BuzzMusic Media had this to say about her third studio album: “The entire album 'A Specyal Remedy' was a captivating piece. From Specyal T’s unique vocal delivery to her array of atmospheres, the album is not one to miss.” Her fourth studio album, Foresight, has already shown considerable interest with it’s first two singles rotating on CBC Radio 1 and featured on ‘The Eh List.’ The lead single “Double Take” is also featured in the movie ‘Alarmed’ (2023) staring Pooch Hall. Same vision, sharper focus!</p>
      
      <span className={style.subtitle}>Come experience her Vibe!</span>

    </div>
  </Layout>
)

export default Bio

export const query = graphql`
  query {
    ProfileImage: file(relativePath: {eq: "st_home.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

